.modal {
  display: flex;

  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.55);
}

.modalContainer {
  /* width: 705px; */

  height: 900px;
  padding-right: 50px;
  padding-left: 50px;
  background-color: #ffffff;
}

.close {
  width: 35px;
  height: 35px;
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 18px;
  /* identical to box height */

  text-align: center;

  color: #f56666;
  background-color: rgba(245, 102, 102, 0.12);
}

.headerContainer {
  margin-top: 86px;
  margin-bottom: 42px;
  width: 600px;
  height: 90px;
}

.header {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  margin-bottom: 23px;
  /* or 160% */

  display: flex;
  align-items: center;
  text-align: center;

  color: #222222;
}

.desc {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 25px;
  /* or 156% */

  text-align: center;

  color: #7d7d7d;
}

.labelComment {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  color: #272828;
}

.commentArea {
  width: 600px;
  height: 218px;
  background: #ffffff;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 3px;
  resize: none;
}

.contactLabel {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  color: #272828;
}

.serviceInput {
  width: 600px;
  height: 50px;
  background: #ffffff;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 3px;
}

.dayInput {
  width: 288px;
  height: 50px;
  background: #ffffff;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 3px;
}

.dayInput::placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  color: #b7b8b7;
}

.timeInput {
  width: 288px;
  height: 50px;
  background: #ffffff;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 3px;
}

.timeInput::placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  color: #b7b8b7;
}

.sendButton {
  margin-top: 40px;
  margin-bottom: 51px;
  width: 163px;
  height: 50px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  text-transform: uppercase;

  color: #ffffff;
  background: #8ac04a;
  border: 1px solid #8ac04a;
  box-sizing: border-box;
  border-radius: 3px;
}

.dropdownBox {
  height: 50px !important;
  /* padding: 1.25rem 1rem !important; */
  font-size: 20px !important;
  /* margin-top: 0.5rem !important; */
  border-radius: 5px !important;
  border: 1px solid black !important;
  max-width: unset !important;
}
