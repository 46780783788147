.subscriptions h2 {
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  color: #222222;
}

.subscriptions .subscription > h2 {
  padding: 40px 35px 0 35px;
}

.subscriptions .disableDelete {
  min-height: 71px;
}

.subscriptions .searchContainer input::placeholder {
  color: #ffffff;
}

.subscriptions .pageBanner {
  height: 200px;
}

.subscriptions .pageBanner .container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  padding: unset;
}

.subscriptions .pageBanner .searchContainer {
  position: relative;
}

.subscriptions .pageBanner .searchContainer svg {
  position: absolute;
  right: 15px;
  top: 12px;
  color: #ffffff;
  cursor: pointer;
}

.subscriptions .pageBanner .searchContainer input {
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  width: 400px;
  background: rgba(34, 34, 34, 0.4);
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-sizing: border-box;
  border-radius: 3px;
  padding: 12px 15px;
  outline: none;
}

.subscriptions .tabContainer {
  position: absolute;
  bottom: 4px;
  left: 30px;
}

.subscriptions .tabContainer .tabHeader {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 17px;
  line-height: 20px;
  text-transform: uppercase;
  color: #ffffff;
  background: rgb(242 242 242 / 40%);
  border: unset;
  border-radius: 3px;
  padding: 12px 20px;
  cursor: pointer;
  position: relative;
  margin-right: 3px;
}

.subscriptions .tabContainer .tabHeader:hover,
.subscriptions .tabContainer .tabHeader.active {
  color: #222222;
  background: #f2f2f2;
  border-radius: 3px 3px 0px 0px;
}

.subscriptions .tabContainer .tabHeader:hover::after,
.subscriptions .tabContainer .tabHeader.active::after {
  content: "";
  width: 100%;
  height: 4px;
  background-color: #f2f2f2;
  position: absolute;
  bottom: -4px;
  left: 0;
}

.subscriptions .mainContent {
  margin: 30px 0;
  min-height: unset;
}

.subscriptions .tableContainer {
  background-color: #ffffff;
  padding: 40px 30px 65px 30px;
}

.subscriptions .tableContainer .tableListCol {
  display: flex;
}

.subscriptions .tableContainer .lightBlueBtn {
  font-family: "Rubik";
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  padding: 10px;
  border-radius: 3px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.subscriptions .tableContainer button > * {
  margin-right: 5px;
}

.subscriptions .tableContainer .lightBlueBtn {
  color: #388cef;
  border: 1px solid #ebf4fd;
  background-color: #ebf4fd;
}

.subscriptions .tableContainer .lightBlueBtn:hover {
  color: #ffffff;
  border: 1px solid #388cef;
  background-color: #388cef;
}

.subscriptions .tableHeader {
  background-color: #8ac04a;
}

.subscriptions .tableHeader .tableListCol {
  align-items: center;
  padding: 18px 20px;
}

.subscriptions .tableHeader .tableListCol .tableMenuItem {
  cursor: pointer;
}

.subscriptions .tableHeader .tableListCol .tableMenuItem svg {
  display: unset;
  vertical-align: unset;
}

.subscriptions .tableHeader .tableListCol .tableMenu {
  padding: 12px 20px;
  margin-left: -20px;
  display: inline-block;
}

.subscriptions .tableHeader .tableListCol .tableMenu.active {
  display: inline-block;
  background-color: #222222;
  border-radius: 3px 3px 0px 0px;
}

.subscriptions .tableHeader .tableListCol .tableMenu.active ~ .tableSubMenu {
  display: block;
}

.subscriptions .tableHeader .tableListCol .tableSubMenu {
  position: absolute;
  top: 52px;
  left: -20px;
  background: #222222;
  border-radius: 0px 3px 3px 3px;
  display: none;
}

.subscriptions .tableHeader .tableListCol .tableSubMenu li:not(:last-child) {
  border-bottom: 1px solid rgb(255 255 255 / 10%);
}

.subscriptions .tableHeader .tableListCol .tableSubMenu li {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  padding: 12px 20px;
  cursor: pointer;
}

.subscriptions .tableHeader .tableListCol > li {
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  position: relative;
}

.subscriptions .tableBody {
  background-color: #ffffff;
  border: 1px solid #e7e7e7;
  box-sizing: border-box;
  border-radius: 0 3px 0 3px;
}

.subscriptions .tableBody .tableListCol {
  padding: 20px;
}

.subscriptions .tableBody .tableListCol:not(:last-child) {
  border-bottom: 1px solid #e7e7e7;
}

.subscriptions .tableBody .tableListCol li {
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  color: #222222;
  display: flex;
  align-items: center;
}

.subscriptions .tableBody .tableListCol li:first-child {
  color: #000000;
}

.subscriptions .tableBody .tableListCol li strong {
  font-family: "Rubik";
  font-weight: 400;
}

.subscriptions .tableBody .tableListCol li:nth-child(5) {
  display: flex;
  justify-content: flex-end;
}

.subscriptions .tableListCol li:not(:last-child) {
  padding-right: 10px;
}

.subscriptions .tableListCol li:nth-child(5) {
  width: 19%;
}

.subscriptions .tableListCol > li:nth-child(4) {
  width: 21%;
}

.subscriptions .tableListCol > li:nth-child(3) {
  width: 20%;
}

.subscriptions .tableListCol > li:nth-child(2) {
  width: 20%;
  justify-content: unset;
}

.subscriptions .tableListCol > li:nth-child(1) {
  width: 20%;
}

.subscriptions .addSubscription {
  background-color: #ffffff;
  padding: 35px 30px 35px 30px;
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.subscriptions .addSubscription .addSubscriptionContent {
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 20px;
}

.subscriptions .addSubscription .addSubscriptionContent h2 {
  margin-bottom: 15px;
}

.subscriptions .addSubscription .addSubscriptionContent p {
  font-weight: 300;
  font-size: 17px;
  line-height: 28px;
  color: #222222;
  margin: 0;
}

.subscriptions .addSubscription .addSubscriptionButton button {
  font-size: 16px;
  line-height: 40px;
  text-align: center;
  color: #ffffff;
  padding: 5px 25px;
  background: #388cef;
  border: 1px solid #388cef;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.subscriptions .addSubscription .addSubscriptionButton button:hover {
  opacity: 0.8;
}

.subscriptions .addSubscription .addSubscriptionButton button > * {
  margin-right: 10px;
}

.subscriptions .paymentDetailsContainer {
  background-color: #ffffff;
  padding: 50px 30px 50px 30px;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
}

.subscriptions .paymentDetailsContainer .paymentDetailsLeftContent {
  width: 100%;
  margin-bottom: 40px;
}

.subscriptions .paymentDetailsContainer .paymentDetailsLeftContent p {
  font-weight: 300;
  font-size: 17px;
  line-height: 28px;
  color: #222222;
  margin-top: 15px;
  margin-bottom: 0;
}

.subscriptions
  .paymentDetailsContainer
  .paymentDetailsLeftContent
  .allProducts {
  margin-top: 25px;
}

.subscriptions .paymentDetailsContainer .paymentDetailsLeftContent ul li {
  font-weight: 400;
  font-size: 17px;
  line-height: 25px;
  padding: 12px 15px;
  box-sizing: border-box;
  border-radius: 3px;
}

.subscriptions
  .paymentDetailsContainer
  .paymentDetailsLeftContent
  ul
  li:first-child {
  color: #ffffff;
  background: #388cef;
  border: 1px solid #388cef;
}

.subscriptions
  .paymentDetailsContainer
  .paymentDetailsLeftContent
  ul
  li:not(:first-child) {
  color: #272828;
  background: #ffffff;
  border: 1px solid #e7e7e7;
  margin-top: 5px;
}

.subscriptions .paymentDetailsContainer .paymentDetailsRightContent {
  width: 100%;
  box-sizing: border-box;
}

.subscriptions
  .paymentDetailsContainer
  .paymentDetailsRightContent
  .service:not(:last-child) {
  margin-bottom: 10px;
}

.subscriptions
  .paymentDetailsContainer
  .paymentDetailsRightContent
  .subscription,
.subscriptions .paymentDetailsContainer .paymentDetailsRightContent .service {
  background: #ffffff;
  border: 1px solid #e7e7e7;
  box-sizing: border-box;
  border-radius: 3px;
}

.subscriptions .paymentDetailsContainer .paymentDetailsRightContent .service {
  padding: 35px 25px;
}

.subscriptions
  .paymentDetailsContainer
  .paymentDetailsRightContent
  .subscription
  .subscriptionTableHeader
  .tableListCol
  li,
.subscriptions
  .paymentDetailsContainer
  .paymentDetailsRightContent
  .service
  .serviceTableHeader
  .tableListCol
  li {
  font-weight: 400;
}

.subscriptions .serviceTableHeader {
  border-bottom: 1px solid #e7e7e7;
}

.subscriptions
  .paymentDetailsContainer
  .paymentDetailsRightContent
  .subscription
  .subscriptionTableBody
  .tableListCol
  li,
.subscriptions
  .paymentDetailsContainer
  .paymentDetailsRightContent
  .service
  .serviceTableBody
  .tableListCol
  li {
  font-weight: 300;
}

.subscriptions
  .paymentDetailsContainer
  .paymentDetailsRightContent
  .service
  .serviceTableBody
  .tableListCol
  li
  .paymentDetails,
.subscriptions
  .paymentDetailsContainer
  .paymentDetailsRightContent
  .service
  .serviceTableBody
  .tableListCol
  li
  .paymentFailedText {
  font-size: 14px;
  line-height: 20px;
  display: block;
  text-decoration-line: underline;
  margin-top: 10px;
}

.subscriptions
  .paymentDetailsContainer
  .paymentDetailsRightContent
  .service
  .serviceTableBody
  .tableListCol
  li
  .paymentDetails {
  color: #388cef;
}

.serviceTableBody .tableListCol:not(:last-child) {
  border-bottom: 1px solid #e7e7e7;
}

.subscriptions
  .paymentDetailsContainer
  .paymentDetailsRightContent
  .service
  .serviceTableBody
  .tableListCol
  li
  .paymentFailedText {
  color: #ff8888;
}

.subscriptions
  .paymentDetailsContainer
  .paymentDetailsRightContent
  .subscription
  .subscriptionTableBody
  .tableListCol
  li
  .removeBtn {
  color: #f56666;
  background: rgba(245, 102, 102, 0.1);
  border: 1px solid rgba(228, 84, 84, 0.2);
  box-sizing: border-box;
  border-radius: 3px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.subscriptions
  .paymentDetailsContainer
  .paymentDetailsRightContent
  .subscription
  .subscriptionTableBody
  .tableListCol
  li
  .removeBtn:hover {
  color: #ffffff;
  background: #f56666;
  border: 1px solid #f56666;
}

.subscriptions
  .paymentDetailsContainer
  .paymentDetailsRightContent
  .subscription
  .tableListCol
  li,
.subscriptions
  .paymentDetailsContainer
  .paymentDetailsRightContent
  .service
  .tableListCol
  li {
  font-size: 14px;
  line-height: 22px;
  color: #272828;
}

.subscriptions
  .paymentDetailsContainer
  .paymentDetailsRightContent
  .service
  .tableListCol
  li.paymentFailed {
  color: #ff8888 !important;
}

.subscriptions
  .paymentDetailsContainer
  .paymentDetailsRightContent
  .service
  .tableListCol
  li
  .paymentFrequency {
  display: flex;
  justify-content: space-between;
}

.subscriptions
  .paymentDetailsContainer
  .paymentDetailsRightContent
  .service
  .tableListCol
  li
  a {
  font-family: "Rubik", sans-serif;
  font-size: 10px;
  font-weight: 400;
  display: flex;
  align-items: center;
  color: #ffffff;
  padding: 3px 10px;
  border-radius: 3px;
  margin: 0;
  cursor: pointer;
}

.subscriptions
  .paymentDetailsContainer
  .paymentDetailsRightContent
  .service
  .tableListCol
  li
  a:hover {
  opacity: 0.8;
}

.subscriptions
  .paymentDetailsContainer
  .paymentDetailsRightContent
  .service
  .tableListCol
  li
  a
  > * {
  margin-right: 5px;
}

.subscriptions
  .paymentDetailsContainer
  .paymentDetailsRightContent
  .service
  .tableListCol
  li
  .upGrade {
  background-color: #8ac04a;
  border: 1px solid #8ac04a;
}

.subscriptions
  .paymentDetailsContainer
  .paymentDetailsRightContent
  .service
  .tableListCol
  li
  .downGrade {
  background-color: #f56666;
  border: 1px solid #f56666;
}

.subscriptions
  .paymentDetailsContainer
  .paymentDetailsRightContent
  .service
  .tableListCol
  li
  .resubscribe {
  background-color: #388cef;
  border: 1px solid #388cef;
}

.subscriptions
  .paymentDetailsContainer
  .paymentDetailsRightContent
  .subscription
  .paymentMethod
  p {
  font-weight: 300;
  font-size: 17px;
  line-height: 25px;
  color: #272828;
  margin: 5px 0 0 0;
  display: flex;
  align-items: center;
}

.subscriptions
  .paymentDetailsContainer
  .paymentDetailsRightContent
  .subscription
  .paymentMethod
  p
  .cardType {
  display: inline-flex;
  margin-left: 10px;
  gap: 5px;
}

.subscriptions
  .paymentDetailsContainer
  .paymentDetailsRightContent
  .subscription
  .tableListCol,
.subscriptions
  .paymentDetailsContainer
  .paymentDetailsRightContent
  .service
  .tableListCol {
  display: flex;
  padding: 20px 0;
}

.subscriptions
  .paymentDetailsContainer
  .paymentDetailsRightContent
  .subscription
  .tableListCol {
  padding: 10px 10px 10px 35px;
  align-items: center;
}

.subscriptions
  .paymentDetailsContainer
  .paymentDetailsRightContent
  .subscription
  .tableListCol
  li:not(:last-child) {
  padding-right: 10px;
  box-sizing: border-box;
}

.subscriptions
  .paymentDetailsContainer
  .paymentDetailsRightContent
  .subscription
  .tableListCol
  li:nth-child(1) {
  width: 33%;
}

.subscriptions
  .paymentDetailsContainer
  .paymentDetailsRightContent
  .subscription
  .tableListCol
  li:nth-child(2) {
  width: 32%;
}

.subscriptions
  .paymentDetailsContainer
  .paymentDetailsRightContent
  .subscription
  .tableListCol
  li:nth-child(3) {
  width: 35%;
  display: flex;
  justify-content: flex-end;
}

.subscriptions
  .paymentDetailsContainer
  .paymentDetailsRightContent
  .service
  .tableListCol
  li:not(:last-child) {
  padding-right: 10px;
  box-sizing: border-box;
}

.subscriptions
  .paymentDetailsContainer
  .paymentDetailsRightContent
  .service
  .tableListCol
  li:nth-child(1) {
  width: 35%;
}

.subscriptions
  .paymentDetailsContainer
  .paymentDetailsRightContent
  .service
  .tableListCol
  li:nth-child(2) {
  width: 20%;
}

.subscriptions
  .paymentDetailsContainer
  .paymentDetailsRightContent
  .service
  .tableListCol
  li:nth-child(3) {
  width: 20%;
}

.subscriptions
  .paymentDetailsContainer
  .paymentDetailsRightContent
  .service
  .tableListCol
  li:nth-child(4) {
  width: 25%;
}

.subscriptions .paymentDetailsRightContent .subscriptionTable,
.subscriptions .paymentDetailsRightContent .serviceTable {
  margin-top: 20px;
}

.subscriptions .paymentDetailsRightContent .subscriptionTable .tableListCol {
  border-bottom: 1px solid #e7e7e7;
}

.subscriptions .paymentMethod {
  padding: 40px 35px 50px 35px;
}

.subscriptions .inputContainer {
  margin-top: 35px;
}

.subscriptions .inputContainer .inputRow {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 15px;
}

.subscriptions .inputContainer .inputRow:not(:last-child) {
  margin-bottom: 18px;
}

.subscriptions .inputContainer .inputRow .inputCol {
  position: relative;
}

.subscriptions .inputContainer .inputRow .inputCol svg {
  position: absolute;
  bottom: 15px;
  left: 15px;
  color: #c4c4c4;
}

.subscriptions .inputContainer .inputRow .inputCol label {
  font-size: 17px;
  font-weight: 400;
  line-height: 20px;
  color: #222222;
  display: block;
  margin-bottom: 5px;
}

.subscriptions .inputContainer .inputRow .inputCol label .required {
  color: #f56666;
}

.subscriptions .inputContainer .inputRow .inputCol input {
  font-family: "Rubik", sans-serif;
  font-weight: 300;
  font-size: 17px;
  line-height: 20px;
  background: #ffffff;
  border: 1px solid #222222;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 15px;
  width: 100%;
  outline: none;
}

.subscriptions .inputContainer .inputBtn {
  display: flex;
  justify-content: center;
}

.subscriptions .inputContainer .inputBtn button {
  font-size: 16px;
  color: #ffffff;
  background: #8ac04a;
  border: 1px solid #8ac04a;
  border-radius: 3px;
  padding: 15px 25px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.subscriptions .inputContainer .inputBtn button:hover {
  opacity: 0.8;
}

.subscriptions .inputContainer .inputBtn button > * {
  margin-right: 5px;
}

@media screen and (min-width: 1024px) {
  .subscriptions .addSubscription .addSubscriptionContent {
    max-width: 450px;
    padding-right: 20px;
    margin-bottom: 0;
  }
  .subscriptions .paymentDetailsContainer .paymentDetailsLeftContent {
    width: 30%;
    margin-bottom: 0;
  }
  .subscriptions .paymentDetailsContainer .paymentDetailsRightContent {
    width: 70%;
    padding-left: 50px;
  }
}

@media screen and (min-width: 1025px) {
  .subscriptions .tableContainer {
    padding: 50px 50px 75px 50px;
  }
  .subscriptions .tableContainer .lightBlueBtn {
    font-size: 16px;
    line-height: 19px;
    padding: 15px;
  }
  .subscriptions .tableHeader .tableListCol > li {
    font-size: 20px;
    line-height: 28px;
  }
  .subscriptions .tableBody .tableListCol li {
    font-size: 16px;
    line-height: 25px;
  }
  .subscriptions .addSubscription {
    padding: 45px 60px 45px 50px;
  }
  .subscriptions .addSubscription .addSubscriptionButton button {
    font-size: 19px;
    line-height: 47px;
  }
  .subscriptions .paymentDetailsContainer {
    padding: 60px 60px 60px 50px;
  }
  .subscriptions .paymentDetailsContainer .paymentDetailsRightContent .service {
    padding: 40px 35px;
  }
  .subscriptions .inputContainer .inputBtn button {
    font-size: 19px;
  }
}

@media screen and (min-width: 1150px) {
  .subscriptions .addSubscription .addSubscriptionContent {
    max-width: 700px;
  }
}

@media screen and (min-width: 1250px) {
  .subscriptions .tableListCol li:nth-child(5) {
    width: 15%;
  }
  .subscriptions .tableListCol > li:nth-child(4) {
    width: 15%;
  }
  .subscriptions .tableListCol > li:nth-child(1) {
    width: 30%;
  }
}

@media screen and (min-width: 1200px) {
  .subscriptions
    .paymentDetailsContainer
    .paymentDetailsRightContent
    .subscription
    .tableListCol
    li:nth-child(1) {
    width: 25%;
  }
  .subscriptions
    .paymentDetailsContainer
    .paymentDetailsRightContent
    .subscription
    .tableListCol
    li:nth-child(2) {
    width: 25%;
  }
  .subscriptions
    .paymentDetailsContainer
    .paymentDetailsRightContent
    .subscription
    .tableListCol
    li:nth-child(3) {
    width: 50%;
  }
  .subscriptions
    .paymentDetailsContainer
    .paymentDetailsRightContent
    .service
    .tableListCol
    li:nth-child(1) {
    width: 25%;
  }
  .subscriptions
    .paymentDetailsContainer
    .paymentDetailsRightContent
    .service
    .tableListCol
    li:nth-child(2) {
    width: 22%;
  }
  .subscriptions
    .paymentDetailsContainer
    .paymentDetailsRightContent
    .service
    .tableListCol
    li:nth-child(4) {
    width: 30%;
  }
}

@media screen and (min-width: 1440px) {
  .subscriptions
    .paymentDetailsContainer
    .paymentDetailsRightContent
    .service
    .tableListCol
    li
    a {
    font-size: 13px;
    padding: 5px 12px;
  }
  .subscriptions
    .paymentDetailsContainer
    .paymentDetailsRightContent
    .subscription
    .tableListCol
    li,
  .subscriptions
    .paymentDetailsContainer
    .paymentDetailsRightContent
    .service
    .tableListCol
    li {
    font-size: 17px;
    line-height: 25px;
  }
}
