.pageBanner {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.pageBanner .container {
  padding: 140px 0;
}

.container {
  width: 1440px;
  max-width: calc(100% - 2 * var(--horspace));
  margin: auto;
  box-sizing: border-box;
}

.mainContent {
  margin: 30px 0 30px 0;
}

.pageBannerContent {
  max-width: 700px;
}

.pageBannerContent h2 {
  font-size: 40px;
  font-weight: 400;
  line-height: 50px;
  color: #ffffff;
  margin-bottom: 15px;
}

.pageBannerContent p {
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  color: #ffffff;
  margin: 0;
}

.appList {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: 15px;
  grid-row-gap: 15px;
}

.appList li {
  position: relative;
  padding: 45px 25px 40% 25px;
  box-sizing: border-box;
}

.img_wrap {
  position: absolute;
  right: 0px;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  flex-shrink: 0;
  max-width: 100%;
  margin: 0;
  vertical-align: middle;
}

.img_wrap img {
  width: 92%;
  max-width: 100%;
  height: auto;
  display: block;
}

.appList li.qip {
  background-color: #8ac04a;
}

.appList li.portal {
  background-color: #388cef;
}

.appList li span {
  color: #ffffff;
  display: block;
}

.appList li .appName {
  font-size: 27px;
  line-height: 43px;
  margin-bottom: 12px;
}

.appList li .appDesc {
  font-size: 20px;
  line-height: 32px;
  margin-bottom: 20px;
}

.appList li .appButtons {
  display: flex;
  flex-wrap: wrap;
}

.appList li .appButtons a {
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  color: #f9f9f9;
  background: #222222;
  border-radius: 3px;
  padding: 15px;
  display: inline-flex;
  align-items: center;
}

.notActive {
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  color: #f9f9f9 !important;
  background: #f56666 !important;
  border-radius: 3px;
  padding: 15px;
  display: inline-flex;
  align-items: center;
}

.appList li .appButtons a:hover {
  opacity: 0.8;
}

.appList li .appButtons a:first-child {
  margin-right: 5px;
  margin-bottom: 5px;
}

.appList li .appButtons a > * {
  margin-right: 12px;
}

@media screen and (min-width: 375px) {
  .appList li .appButtons {
    flex-wrap: unset;
  }

  .appList li .appButtons a:first-child {
    margin-right: 5px;
    margin-bottom: 0;
  }
}

@media screen and (min-width: 768px) {
  .appList {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 1025px) {
  .pageBannerContent h2 {
    font-size: 55px;
    line-height: 65px;
  }

  .appList li {
    padding: 45px 50px 40% 50px;
  }
}

@media screen and (min-width: 1440px) {
  .pageBanner .container {
    padding: 140px 100px 140px 140px;
  }
}
