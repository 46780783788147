.pageBanner {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
}

.pageBanner .container {
  padding: 150px 0 350px 0;
  display: flex;
  flex-wrap: wrap;
}

.pageBannerContent {
  max-width: 425px;
  margin-bottom: 40px;
}

.pageBannerContent h1 {
  font-size: 30px;
  font-weight: 400;
  line-height: 37px;
  color: #ffffff;
  margin-bottom: 15px;
}

.pageBannerContent p {
  font-size: 17px;
  font-weight: 400;
  line-height: 28px;
  color: #ffffff;
  margin: 0;
}

.formContainer {
  box-sizing: border-box;
  width: 100%;
}

.formContainer .form {
  background-color: #ffffff;
  box-shadow: -12px 2px 31px rgb(0 0 0 / 8%);
  border-radius: 5px;
  padding: 55px 30px 65px 30px;
  box-sizing: border-box;
}

.formContainer .form .InputRow:first-child {
  margin-bottom: 20px;
}

.formContainer .form .InputRow {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}

.formContainer .form .InputRow .inputGroup > label .required {
  color: #f56666;
}
.formContainer .form .InputRow .inputGroup > label {
  font-size: 19px;
  line-height: 23px;
  color: #222222;
  display: block;
  margin-bottom: 5px;
}

.formContainer .form .InputRow .inputGroup > input::placeholder {
  color: #b7b8b7;
}

.formContainer .form .InputRow .inputGroup > input {
  font-weight: 300;
  font-size: 19px;
  line-height: 23px;
  color: #222222;
  border: 1px solid #222222;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 15px;
  width: 100%;
  outline: none;
}

.formContainer .form .InputRow .inputBtn button {
  font-size: 12px;
  line-height: 47px;
  color: #ffffff;
  background: #388cef;
  border: 1px solid #388cef;
  border-radius: 3px;
  padding: 5px 18px;
  cursor: pointer;
}

.formContainer .form .InputRow .inputBtn button:hover {
  opacity: 0.8;
}

.formContainer .form .InputRow .forgotPass a {
  font-size: 17px;
  line-height: 28px;
  text-decoration-line: underline;
  color: #f56666;
}

.formContainer .form .InputRow .forgotPass a:hover {
  opacity: 0.8;
}

/* html { */
	/* min-height: 1000px; */
    /* position: relative; */
/* }  */
 
/* .footer { */
    /* position: absolute; */
    /* bottom: -62px; */
    /* width: 100%;	 */
/* }  */
 
@media screen and (min-width: 641px) {
  .formContainer .form .InputRow .inputBtn button {
    font-size: 15px;
  }

  .formContainer .form .InputRow {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 768px) {
  .formContainer .form .InputRow .inputBtn button {
    font-size: 19px;
  }
}

@media screen and (min-width: 1025px) {
  .pageBanner .container {
    padding: 200px 0 400px 0;
  }
  .pageBannerContent h1 {
    font-size: 40px;
    line-height: 47px;
  }

  .formContainer .form {
    padding: 65px 50px 75px 50px;
  }
}

@media screen and (min-width: 1200px) {
  .pageBanner .container {
    flex-wrap: unset;
  }

  .pageBannerContent {
    margin-bottom: 0;
  }

  .formContainer {
    padding-left: 70px;
  }
}

@media screen and (min-width: 1400px) {
  .formContainer .form {
    width: 680px;
  }
}

@media screen and (min-width: 1440px) {
  .pageBanner .container {
    padding: 300px 100px 500px 160px;
  }
}

 