.container {
  width: 1440px;
  max-width: calc(100% - 2 * var(--horspace));
  margin: auto;
  box-sizing: border-box;
}

.mainContent {
  margin: 45px 0;
  background: #ffffff;
  padding: 60px;
}

.mainContent h2 {
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  color: #222222;
}

.description {
  font-weight: 300;
  font-size: 17px;
  line-height: 28px;
  color: #222222;
  margin-top: 15px;
  margin-bottom: 0;
}

.payBtn {
  border: 1px solid #388cef;
  background-color: #388cef;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
  padding: 10px 25px;
  border-radius: 3px;
  display: flex;
  align-items: center;
}
