.pricingTable {
  padding: 50px 15px 65px;
  box-sizing: border-box;
  background-color: #ffffff;
  max-width: calc(100% - 2 * var(--horspace));
  margin: auto;
}

.pricingTable svg {
  display: unset;
  vertical-align: unset;
}

.pricingTable .pricingPlan {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 35px;
}

.pricingTable .pricingPlan > span {
  font-size: 16px;
  line-height: 28px;
  color: #000000;
  display: block;
}

.pricingTable .switch {
  position: relative;
  display: inline-block;
  width: 65px;
  height: 35px;
  margin: 0 10px;
}

.pricingTable .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.pricingTable .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #8bc334;
  border: 1px solid #8bc334;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.pricingTable .slider:before {
  position: absolute;
  content: "";
  height: 29px;
  width: 29px;
  left: 31px;
  bottom: 2px;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.pricingTable input:checked + .slider {
  background-color: #8bc334;
  border: 1px solid #8bc334;
}

.pricingTable input:focus + .slider {
  box-shadow: 0 0 1px #8bc334;
}

.pricingTable input:checked + .slider:before {
  -webkit-transform: translateX(-29px);
  -ms-transform: translateX(-29px);
  transform: translateX(-29px);
}

.pricingTable .slider.round {
  border-radius: 34px;
}

.pricingTable .slider.round:before {
  border-radius: 50%;
}

.pricingTable .pricingPlanPromo {
  margin-bottom: 45px;
  text-align: center;
}

.pricingTable .pricingPlanPromo span {
  font-weight: 300;
  font-size: 16px;
  line-height: 25px;
  color: #8ac04a;
  background-color: #f3f9ed;
  border-radius: 3px;
  padding: 5px 15px;
  display: inline-block;
}

.pricingTable .feature-item svg {
  height: 21px;
}

.pricingTable .pricingTableList {
  display: flex;
  flex-wrap: wrap;
}

.pricingTable .pricingTableList .pricingTableInfo {
  width: 36.5%;
  border-top: 1px solid #ffffff;
  border-bottom: 1px solid #ffffff;
  display: none;
}

.pricingTable .pricingTableList div:not(.pricingTableInfo) li:first-child {
  border-bottom: 1px solid rgb(0 0 0 / 10%);
  padding: 30px 10px;
  box-sizing: border-box;
}

.pricingTable .pricingTableList ul li .appName {
  font-size: 20px;
  line-height: 28px;
  color: #222222;
}

.pricingTable .pricingTableList ul li .planPrice span {
  font-size: 14px;
  line-height: 22px;
  color: #222222;
}

.pricingTable .pricingTableList ul li .appName {
  display: block;
  width: 100%;
  text-align: left;
}

.pricingTable .pricingTableList ul li .discountPrice {
  font-weight: 300;
  font-size: 16px;
  line-height: 28px;
  color: #9a9a9a;
}

.pricingTable .pricingTableList ul li .planPrice {
  font-size: 27px;
  line-height: 43px;
  color: #222222;
  display: block;
  text-align: left;
}

.priceTag {
  font-size: 27px !important;
  line-height: 43px !important;
  color: #222222 !important;
  display: block !important;
  text-align: left !important;
}

.saveAmount {
  font-weight: 300 !important;
  font-size: 16px !important;
  line-height: 28px !important;
  color: #9a9a9a !important;
}

.pricingTable .pricingTableList ul li .fa-times {
  color: #f56666;
}

.pricingTable .pricingTableList ul li .fa-check {
  color: #8ac04a;
}

.pricingTable
  .pricingTableList
  .pricingTableInfo
  li:not(:first-child)
  .infoText {
  font-size: 16px;
  line-height: 28px;
  color: #000000;
  text-align: unset;
  font-weight: unset;
  display: unset;
}

.pricingTable .pricingTableList .pricingTableInfo li .infoText {
  overflow: hidden;
  max-width: 20ch;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.pricingTable .pricingTableList ul li:not(:first-child):not(:last-child) {
  padding: 12px 15px;
}

.pricingTable .pricingTableList ul li:not(:first-child):not(:last-child) {
  border-bottom: 1px solid rgb(0 0 0 / 10%);
}

.pricingTable .pricingTableList .pricingTableInfo li:last-child {
  padding: 25px 15px;
}
.pricingTable .pricingTableList > div:not(.pricingTableInfo) li:last-child {
  padding: 25px 15px;
  display: flex;
}

.pricingTable
  .pricingTableList
  > div:not(.pricingTableInfo)
  li:last-child
  > span {
  font-size: 20px;
  line-height: 28px;
  color: #222222;
}

.pricingTable
  .pricingTableList
  > div:not(.pricingTableInfo)
  li:last-child
  > span
  .data.mobile-visible {
  font-size: 15px;
  line-height: 17px;
  color: #000000;
  display: inline-block;
  margin-left: 0.5em;
}

.pricingTable .pricingTableList li.mobile-visible .feature svg,
.pricingTable .pricingTableList .pricingTableInfo li:not(:first-child) svg {
  color: #d2d2d2;
}

.pricingTable .pricingTableList > div:not(.pricingTableInfo) {
  width: 100%;
  position: relative;
  box-sizing: border-box;
}

.pricingTable .productContainer:not(:last-child) {
  margin-bottom: 45px;
}

.pricingTable .pricingTableList div:not(.pricingTableInfo) ul {
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  border-radius: 3px;
}

.pricingTable .productContainer.active ul {
  background: #f4f9ed;
  border: 1px solid #c5e49f !important;
}

.pricingTable .pricingTableList .productContainer a {
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  text-transform: uppercase;
  color: #f9f9f9;
  background: #8ac04a;
  border-radius: 3px;
  padding: 15px;
  margin-top: 4px;
  display: block;
}

.pricingTable .pricingTableList .productContainer a.current {
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  text-transform: uppercase;
  color: #c5e49f;
  background: #f4f9ed;
  border-radius: 3px;
  padding: 15px;
  margin-top: 4px;
  display: block;
}

.pricingTable .pricingTableList .productContainer a:hover {
  opacity: 0.8;
}

.pricingTable .pricingTableList .productContainer .productTopText {
  font-weight: 300;
  font-size: 16px;
  line-height: 25px;
  color: #ffffff;
  background: #388cef;
  border-radius: 3px 3px 0px 0px;
  padding: 5px;
  box-sizing: border-box;
  width: 100%;
  text-align: center;
  display: block;
  position: static;
  top: -34px;
  left: 0;
}

.pricingTable .checkboxContainer {
  display: block;
  position: relative;
  margin-top: 3px;
  padding-left: 30px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.pricingTable .checkboxContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.pricingTable .checkboxContainer .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background: #f9f9f9;
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  border-radius: 3px;
}

.pricingTable .checkboxContainer:hover input ~ .checkmark {
  background-color: #ccc;
}

.pricingTable .checkboxContainer input:checked ~ .checkmark {
  background: #8ac04a;
  border: 1px solid #8ac04a;
}

.pricingTable .checkboxContainer .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.pricingTable .checkboxContainer input:checked ~ .checkmark:after {
  display: block;
}

.pricingTable .checkboxContainer .checkmark:after {
  left: 7px;
  top: 3px;
  width: 4px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.freeTrial {
  margin: 50px 0 0 auto;
  max-width: 100%;
}

.freeTrial .freeTrialRow {
  background-color: #f9f9f9;
  border-radius: 3px;
  padding: 20px 25px;
  display: flex;
}

.freeTrial .freeTrialRow svg {
  color: #8ac04a;
}

.freeTrial .freeTrialRow p {
  font-weight: 300;
  font-size: 16px;
  line-height: 25px;
  color: #7d7d7d;
  margin: 0;
  padding-left: 20px;
}

.freeTrial .freeTrialRow p a {
  color: #85c340;
}

.pricingTable .tooltip {
  display: inline-flex;
  align-items: center;
  margin-bottom: 5px;
  margin-left: 0.5em;
  cursor: pointer;
  position: relative;
  vertical-align: middle;
}

.pricingTable .tooltip:hover::after {
  transition: all 0.65s cubic-bezier(0.84, -0.18, 0.31, 1.26);
  opacity: 1;
  transform: scale(1) translateY(0);
  z-index: 100;
}

.pricingTable .tooltip::after {
  background: #fff;
  border-radius: 0.25em;
  bottom: 180%;
  left: 50%;
  opacity: 0;
  position: absolute;
  z-index: -100;
  color: #222;
  content: attr(data-tip);
  margin-left: -8.75em;
  padding: 1.5em;
  transition: all 0.65s cubic-bezier(0.84, -0.18, 0.31, 1.26) 0.2s;
  transform: scale(0.6) translateY(50%);
  width: 20em;
  box-shadow: 0 0 1em rgb(0 0 0 / 10%);
  font-size: 16px;
  line-height: 22px;
}

@media screen and (max-width: 1023px) {
  .pricingTable .pricingTableList ul li.feature-item.mobile-visible {
    display: flex;
  }

  .pricingTable
    .pricingTableList
    ul
    li.feature-item.mobile-visible
    .feature
    .infoText {
    font-size: 15px;
  }

  .pricingTable .pricingTableList ul li.feature-item.mobile-visible .feature {
    padding-left: 8px;
    display: flex;
    flex: 1;
    justify-content: space-between;
  }

  .pricingTable .pricingTableList ul li.feature-item:not(.mobile-visible) {
    display: none;
  }

  .pricingTable .tooltip::after {
    margin-left: -15em;
    margin-bottom: -15px;
    width: 16em;
  }
}

@media screen and (min-width: 1024px) {
  .pricingTable .productContainer:not(:last-child) {
    margin-bottom: 0;
  }

  .pricingTable .pricingTableList div:not(.pricingTableInfo) ul {
    text-align: center;
  }

  .pricingTable .pricingTableList .pricingTableInfo li:first-child {
    height: 220px;
  }

  .pricingTable .pricingTableList div:not(.pricingTableInfo) li:first-child {
    height: 220px;
    position: relative;
  }

  .pricingTable .pricingTableList ul li:first-child > div {
    position: absolute;
    top: 30px;
    left: 5px;
    right: 5px;
    bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .pricingTable .pricingTableList ul li .appName,
  .pricingTable .pricingTableList ul li .planPrice {
    text-align: center;
  }

  .pricingTable
    .pricingTableList
    > div:not(.pricingTableInfo)
    li:last-child
    > span
    .data.mobile-visible {
    display: none;
  }

  .pricingTable .pricingTableList > div:not(.pricingTableInfo) li:last-child {
    justify-content: center;
  }

  .pricingTable .pricingTableList .pricingTableInfo {
    display: block;
  }

  .pricingTable .pricingTableList ul li.feature-item.mobile-visible .feature {
    display: none;
  }

  .pricingTable .pricingTableList > div:not(.pricingTableInfo) {
    width: 15.87%;
    padding-right: 4px;
  }

  .pricingTable .pricingTableList > div:last-child {
    padding-right: 0px;
  }

  .pricingTable .pricingTableList .productContainer .productTopText {
    width: calc(100% - 4px);
    position: absolute;
  }

  .freeTrial {
    max-width: 63.5%;
  }
}

@media screen and (min-width: 1370px) {
  .pricingTable .pricingTableList .pricingTableInfo li .infoText {
    max-width: 34ch;
  }
}

@media screen and (max-width: 1300px) {
  .pricingTable .pricingTableList .pricingTableInfo li .feature .infoText {
    font-size: 14px;
  }
}
