.mainContainer {
  display: flex;
  align-items: center;
  height: 100vh;
}

.cardContainer {
  max-width: 500px;
  margin: auto;
  padding: 40px;
  background-color: white;
}

.infoIcon {
  width: 130px;
  margin: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 2.3rem 2rem;
  margin-bottom: 1.5rem;
  background-color: #feeded;
}

.infoText {
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  font-weight: normal;
  display: block;
}

@media only screen and (max-width: 450px) {
  .cardContainer {
    margin: auto 2rem auto 2rem;
  }
}
