.actionBtns button {
  color: #fff;
  padding: 15px;
  font-size: 19px;
  border-radius: 3px;
}

.tryAgain {
  background: #388cef;
}

.getNew {
  background: #f56666;
}

.textPrompt {
  font-size: 25px;
  max-width: 410px;
  text-align: center;
  line-height: 30px;
}
