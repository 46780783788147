.pageBanner {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.pageBanner .container {
  padding: 140px 0;
}

.container {
  width: 1440px;
  max-width: calc(100% - 2 * var(--horspace));
  margin: auto;
  box-sizing: border-box;
}

.pageBannerContent {
  max-width: 700px;
}

.pageBannerContent h2 {
  font-size: 40px;
  font-weight: 400;
  line-height: 50px;
  color: #ffffff;
  margin-bottom: 15px;
  max-width: 630px;
}

.pageBannerContent p {
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  color: #ffffff;
  margin: 0;
}

.description p {
  font-size: 20px;
}

.description h2 {
  font-size: 40px;
}
