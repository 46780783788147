.pageBanner {
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  position: relative;
  z-index: 1;
}

.pageBanner::after {
  content: "";
  background: rgba(46, 46, 46, 0.4);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
}

.titleBanner {
  font-size: 40px;
  color: #ffffff;
}
