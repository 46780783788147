.plans .pageBanner {
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.plans h2 {
  font-size: 20px;
}

.plans .titleBanner {
  font-size: 40px;
  color: #ffffff;
}
