.signUp .signUpContainer {
  display: flex;
  flex-wrap: wrap;
  background-color: #ffffff;
  padding: 50px 30px 90px 30px;
}

.signUp .signUpContainer .signUpText {
  width: 100%;
  margin-bottom: 40px;
}

.signUp .signUpContainer .signUpText p {
  font-weight: 300;
  font-size: 17px;
  line-height: 28px;
  color: #222222;
  margin-top: 15px;
  margin-bottom: 0;
}
.signUp .signUpContainer .signUpText h2 {
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  color: #222222;
}

.signUp .signUpContainer .signUpInput {
  width: 100%;
  box-sizing: border-box;
}

.signUp .signUpContainer .signUpInput .inputRow.oneCol {
  grid-template-columns: repeat(1, 1fr);
}

.signUp .signUpContainer .signUpInput .inputRow:not(:last-child) {
  margin-bottom: 18px;
}

.signUp .signUpContainer .signUpInput .inputRow {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: 15px;
  grid-row-gap: 18px;
}

.signUp .signUpContainer .signUpInput .inputCol label {
  font-size: 17px;
  font-weight: 400;
  line-height: 20px;
  color: #222222;
  display: block;
  margin-bottom: 5px;
}

.signUp .signUpContainer .signUpInput .inputCol label .required {
  color: #f56666;
}

/* ::placeholder {
  color: #b7b8b7;
} */

.signUp .signUpContainer .signUpInput .inputCol select,
.signUp .signUpContainer .signUpInput .inputCol input {
  font-family: "Rubik", sans-serif;
  font-weight: 300;
  font-size: 17px;
  line-height: 20px;
  background: #ffffff;
  border: 1px solid #222222;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 15px;
  width: 100%;
  outline: none;
}

.signUp .walkthrough {
  margin: 55px 0 25px 0;
}
.signUp .walkthrough p {
  font-weight: 300;
  font-size: 17px;
  line-height: 28px;
  color: #222222;
  margin: 12px 0 0 0;
}
.signUp .walkthrough span {
  font-size: 20px;
  line-height: 28px;
  color: #222222;
  display: block;
}

.signUp .inputBtn {
  margin-top: 25px;
}

.signUp .inputBtn .btnBlue {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 47px;
  text-align: center;
  text-transform: uppercase;
  border-radius: 3px;
  color: #f9f9f9;
  padding: 5px 20px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  border: 1px solid #388cef;
  background-color: #388cef;
}

.signUp .inputBtn .btnBlue:hover {
  opacity: 0.8;
}

.toggleShowPassword {
  float: right;
  margin-left: -90px;
  margin-top: 14px;
  z-index: 2;
}

@media screen and (min-width: 641px) {
  .signUp .signUpContainer .signUpInput .inputRow {
    grid-template-columns: repeat(2, 1fr);
  }

  .signUp .inputBtn .btnBlue {
    font-size: 19px;
  }
}

@media screen and (min-width: 1024px) {
  .signUp .signUpContainer .signUpText {
    width: 30%;
    margin-bottom: 0;
  }

  .signUp .signUpContainer .signUpInput {
    width: 70%;
    padding-left: 50px;
  }
}

@media screen and (min-width: 1025px) {
  .signUp .pageBanner .pageBannerContent h1 {
    font-size: 40px;
    line-height: 32px;
  }

  .signUp .signUpContainer {
    padding: 60px 50px 100px 50px;
  }
}
