.pagination {
  margin: 35px 0px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 5px;
}

.pagination a {
  font-weight: 300;
  font-size: 16px;
  width: 50px;
  height: 50px;
  color: #000000;
  background-color: #ffffff;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination a:not(:last-child) {
  margin-right: 4px;
}

.pagination a:hover,
a.active {
  color: #ffffff;
  background-color: #8ac04a;
  border: 1px solid #8ac04a;
}
