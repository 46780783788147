.container {
  width: 1440px;
  max-width: calc(100% - 2 * var(--horspace));
  margin: auto;
  box-sizing: border-box;
}

.mainContent {
  margin: 45px 0;
  background: #ffffff;
  padding: 60px;
}

.mainContent h2 {
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  color: #222222;
}

.method {
  font-size: 17px;
}

.description {
  font-weight: 300;
  font-size: 17px;
  line-height: 28px;
  color: #222222;
  margin-top: 15px;
  margin-bottom: 0;
}

.payBtn {
  border: 1px solid #388cef;
  background-color: #388cef;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
  padding: 10px 25px;
  border-radius: 3px;
  display: flex;
  align-items: center;
}

.removeBtn {
  color: #f56666;
  background: rgba(245, 102, 102, 0.1);
  border: 1px solid rgba(228, 84, 84, 0.2);
  box-sizing: border-box;
  border-radius: 3px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.removeBtn:hover {
  color: #ffffff;
  background: #f56666;
  border: 1px solid #f56666;
}

.table {
  font-size: 17px;
  border-width: 1px;
  border-color: #e7e7e7;
}

.row > div {
  padding: 14px;
}

.row > div:not(:last-child) {
  padding: 14px;
  border-right-width: 1px;
}

.row:not(:last-child) > div {
  border-bottom-width: 1px;
}

.failedAlert {
  font-style: normal;
  font-weight: 100 !important;
  font-size: 17px;
  line-height: 28px;
  /* or 165% */

  color: #f56666;
  background: rgba(245, 102, 102, 0.1);
}
