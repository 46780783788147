.formWrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
}

.inputWrapper {
  display: flex;
  flex-direction: column;
}

.inputWrapper label {
  margin-bottom: 5px;
}

.inputWrapper input {
  font-weight: 300;
  font-size: 17px;
  line-height: 20px;
  border: 1px solid #222222;
  border-radius: 3px;
  outline: none;
}
