.baseModal {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.55);
}

.modalContainer {
  padding: 5px;
  border-radius: 5px;
  background-color: #fff;
}

.modalContainer button:hover {
  opacity: 0.8;
}

.modalHeader {
  display: flex;
  justify-content: flex-end;
}

.modalHeader button {
  color: #fff;
  padding: 5px;
  border-radius: 3px;
  background: #f56666;
}
